import React from "react"
import PropTypes from "prop-types"
import fbc from "../images/bcFirstDivider.png"
import sbc from "../images/bcSecondDivider.png"
import { IoIosArrowDown } from "react-icons/io";
import { GoLocation } from "react-icons/go";
import Select from 'react-select';
import rappiLogo from './../images/covid/RappiYellow.svg';
import glovoLogo from './../images/covid/GlovoYell.svg';
import pedidosya from './../images/covid/PedidosYaYellow.svg';
import lupuloMenu from '../images/lupulo-verticalmenu.png'
import { db } from '../utils/firebase';
import { Scrollbars } from 'react-custom-scrollbars';
import { LosRefugios } from "./refugios/LosRefugios"
import "firebase/firestore";
import arrow from "../images/simpleArrow.png"


export default class RefugiosComponent extends React.Component {

  static propTypes = {
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      width: 1000,
      options: [],
      refugios: "",
      selected: {value: 'capital', label: 'CABA'},
    }
    this.bottomRefugiosRef = React.createRef()
  }
  
  componentDidMount() {
    let localidades = [];
    this.props.dataGraph.allLocalidades.edges.map(node => {
      localidades = [...localidades, node.node]
    })
    
    this.setState({
      options: localidades.filter((localidad => {
        let mapeo = this.props.dataGraph.allRefugios.edges.map(node => {
          return node.node.zone === localidad.value
        })
        if(mapeo.includes(true))return true
        return false
      }))
    })
    let zones = {};
    this.props.dataGraph.allRefugios.edges.map(node => {
      if(zones[node.node.zone]){
         zones[node.node.zone].push(node.node)
        } else {
          zones[node.node.zone] = [node.node]    
        }
      })
      this.setState({
        refugios: zones
      })
  }

  

  handleChange = (newValue, actionMeta) => {
    this.setState({
      selected: newValue
    })
  };
  scrollToMyRef = () => {
    document.getElementsByTagName('html')[0].style.scrollBehavior = 'smooth'
    setTimeout(()=>{
      window.scrollTo(0, this.bottomRefugiosRef.current.offsetTop);
    },10)

    setTimeout(()=>{
      this.props.handleDark(true);
      document.getElementsByTagName('html')[0].style.scrollBehavior = 'unset'
    },1000)

  }
  render() {
    return (
      <div className={'refugiosContainer'}>

        <div style={{zIndex: 1}} className={'breadcrumbsContainer'}>
          <div className={'breadcrumbsWrapper'}>
           {/* <span className={'bcSection'}>{this.state.sectionName}</span>
            <img  alt="" className={'fbc'} src={fbc}/>*/}
            <span className={'bcPage'}>Bares y Refugios</span>
            <img  alt="" className={'sbc'} src={sbc}/>
            <img  alt="" className={'bcLupulo'} src={lupuloMenu}/>
          </div>
        </div>
        <section className={'refugiosTopWrapper'}>
          <div className={'refugiosTitleWrapper'}>
            <h2 className={'refugiosTitle'}>
              Date una vuelta y probá
              todas nuestras<span className={'refugiosTitleAdorment'}> variedades.</span>
            </h2>
          </div>
          {/* <a href={'https://patagonia.tuacceso.com.ar/'} className={'reserveButton'}>
            Reservá ahora
          </a> */}
          <div className={'arrowDownContainer'}>
            <IoIosArrowDown onClick={this.scrollToMyRef}/>
          </div>
        </section>
        <section ref={this.bottomRefugiosRef} className={'refugiosBottomWrapper'}>
          <div className={'refugiosBottomTitleWrapper'}>
            <h2 className={'refugiosBottomTitle'}><span className={'refugiosBottomTitleAdorment'}>LA PATAGONIA</span> ESTÁ ACÁ NOMÁS</h2>
          </div>
         {/* <div className="refugiosCovid">
            <h2 className="refugiosCovidText">
              Debido al contexto actual, nuestros Refugios se encuentran temporalmente cerrados al público. Pero continuamos ofreciendo la mejor experiencia cervecera y gastronómica a través de:
              <div className="deliveriesContainer">
                <a href="https://glovoapp.com/" target="_blank" rel="noreferrer"><img className="refugiosCovidCraftImg" src={glovoLogo} alt="GLOVO"/></a> 
                <a href="https://www.rappi.com.ar/" target="_blank" rel="noreferrer"><img className="refugiosCovidImg" src={rappiLogo} alt="RAPPI"/></a>
                <a href="https://www.pedidosya.com.ar/" target="_blank" rel="noreferrer"><img className="refugiosCovidPedidosyaImg" src={pedidosya} alt="PEDIDOSYA"/></a>
              </div>
              
            </h2>
          </div>*/}
          <div className="regufioSelected__container">
            <p className="select__text">ENCONTRÁ TU REFUGIO</p>
            <img id="arrow_find" src={arrow} />
          <Select
            className="refugiosSelectContainer"
            classNamePrefix="refugiosSelect"
            label={'Selecciona'}
            value={this.state.selected}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            name="state"

            onChange={this.handleChange}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: 'rgba(0,127,109,0.55)',
                primary: '#007F6D',
              },
            })}
            options={this.state.options}
          />
          </div>

          
          

          <div className={'sucursales-list'}>
            <LosRefugios zone={this.state.selected.value} refugios={this.state.refugios}/>

          </div>


        </section>

      </div>
    )
  }
}