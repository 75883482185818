import React from "react"
import PropTypes from "prop-types"
import Cookies from "js-cookie"
import SEO from "../components/seo"
import Header from "../components/header"
import BeersMenu from "../components/BeersMenu"
import Menu from "../components/Menu"
import MenuMobile from "../components/MenuMobile"
import Footer from "../components/Footer"
import BirthConsent from "../components/BirthConsent"
import RefugiosComponent from "../components/RefugiosComponent"
import { graphql, StaticQuery } from "gatsby"

const hasConfirmed = () => {
  return (
    (Cookies.get("patagoniaBirthConsent") !== undefined &&
      Cookies.get("patagoniaBirthConsent")) ||
    (localStorage.getItem("patagoniaBirthConsent") !== undefined &&
      localStorage.getItem("patagoniaBirthConsent"))
  )
}

class Refugios extends React.Component {
  static propTypes = {
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      isScrolling: false,
      menuOpen: false,
      menuClass: "slideOut",
      isDark: false,
      width: 1000,
      birthConsent: true,
    }
  }

  handleDark = bool => {
    this.setState({
      isDark: bool,
    })
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll)
    this.setState({
      width: window.innerWidth,
    })

    if (hasConfirmed()) {
      this.handleConsent()
    } else {
      this.setState({
        birthConsent: false,
      })
    }
  }

  handleConsent = () => {
    this.setState({
      birthConsent: true,
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll)
  }

  onMenuToggle = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
      menuClass: this.state.menuOpen ? "slideOut" : "slideIn",
      isDark: !this.state.menuOpen,
    })
  }

  onScroll = () => {
    this.setState({ isScrolling: true })

    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.setState({ isScrolling: false })
    }, 200)

    var section = document.getElementsByTagName("SECTION")

    var bScroll = document.scrollingElement.scrollTop

    for (var i = 0; i < section.length; i++) {
      var sHeight = section[i].offsetHeight
      var offsets = section[i].offsetTop

      if (bScroll > offsets && bScroll < offsets + sHeight) {
        if (section[i].className !== "refugiosTopWrapper") {
          this.setState({ isDark: true })
        } else if (section[i].className === "refugiosTopWrapper") {
          this.setState({ isDark: false })
        }
      } /* else {
        section[i].className = "section";
      }*/
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <SEO title="Refugios" />
        {this.state.birthConsent && (
          <div>
            <Header
              isScrolling={this.state.isScrolling}
              onMenuToggle={this.onMenuToggle}
              isDark={this.state.isDark}
              menuOpen={this.state.menuOpen}
              width={this.state.width}
            />
            <RefugiosComponent handleDark={this.handleDark} dataGraph={this.props.data} />
            {this.state.width > 767 ? (
              <Menu
                menuClass={this.state.menuClass}
                menuOpen={this.state.menuOpen}
              />
            ) : (
              <MenuMobile
                onMenuToggle={this.onMenuToggle}
                menuClass={this.state.menuClass}
                menuOpen={this.state.menuOpen}
              />
            )}
            <Footer />
          </div>
        )}
        {!this.state.birthConsent && (
          <BirthConsent handleConsent={this.handleConsent} />
        )}
      </div>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query QueryRefugios {
        allLocalidades (filter: {value: {ne: "nueva"}}, sort: {fields: index}) {
          edges {
            node {
              index
              label
              value
            }
          }
        }
        allRefugios (sort: {fields: index})  {
          edges {
            node {
              address
              index
              isLarge
              mapLink
              outOfOrder
              reserveLink
              src
              tel
              title
              zone
            }
          }
        }
      }
    `}
    render={data => <Refugios data={data} />}
  />
)
