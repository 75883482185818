import React, { useState, useEffect } from "react"


export function LosRefugios({zone, refugios}) {

  return (
    refugios.capital ? (
    <div>
      {refugios[zone].map(function(item, idx) {
        let className = 'sucursales-list-item' + (item.isLarge ? ' large' : '') + (item.outOfOrder ? ' large' : '')
        return(

          <div key={idx} className={className} data-category={item.zone}>
            <img className="is-mobile" src={item.src}
                 alt={'Foto de ' + item.title}/>
            <img className="is-desktop" src={item.src}
                 alt={'Foto de ' + item.title}/>

            {item.outOfOrder && <div className="out-of-order-wrap"></div>}
            {item.outOfOrder &&
            <div className="out-of-order">
              <img src="../asset_en_obra.png" alt="Ícono de herramientas"/>
              <div>
                <h3>En obra</h3>
                <p>Proximamente</p>
              </div>
            </div>
            }

            <div className="sucursales-list-container">
              <h2>{item.title}</h2>
              {!item.outOfOrder &&
              <div className="sucursales-list-info">
                {item.address && <address>{item.address}</address>}
                {item.tel && <a href={'tel:' + item.tel} data-event="Telefono" data-label={item.tel}>
                  {'Tel. ' + item.tel}
                </a>}
                <div className="button-links">
                  {item.reserveLink &&
                  <span className="map-button reservation">
                      <a href={item.reserveLink}
                         target="_blank" rel="noreferrer">reservar
                      </a>
                    </span>
                  }

                  {item.mapLink &&
                  <span className="map-button">
                      <a href={item.mapLink}
                         target="_blank" rel="noreferrer" data-event="Ver mapa"
                         data-label={item.title}>Ver mapa <span>→</span>
                      </a>
                    </span>
                  }

                </div>
              </div>
              }

            </div>
          </div>
        )

      })}
    </div>
    ) : null
  )
}