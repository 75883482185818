import * as firebase from "firebase/app";

let config = {
    apiKey: "AIzaSyB2dybvT5aSrbHAJrExbc6FfFDHIn_84z8",
    authDomain: "patagonialanding.firebaseapp.com",
    databaseURL: "https://patagonialanding.firebaseio.com",
    projectId: "patagonialanding",
    storageBucket: "patagonialanding.appspot.com",
    messagingSenderId: "124794361373",
    appId: "1:124794361373:web:931e8b03b108077678d8f4",
    measurementId: "G-RYTH321N31"
};
firebase.initializeApp(config);


export default firebase;
